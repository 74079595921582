import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MenuComponent from "../home/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import { auth } from "../../firebase/initFirebase";
import {Instagram} from "@mui/icons-material";
import ChefsAndHomeLogo from "../chefs_and_home_logo.png";
import ChefsAndHomeMobileLogo from "../chefs_and_home_mobile_logo.png";
import ShutdownModal from "../home/ShutdownModal";

const appBarStyle = {
  backgroundColor: "transparent",
  fontFamily: "DM Serif Display, sans-serif",
  boxShadow: "none",
  color: "black",
};

const buttonStyle = {
  color: "black",
  fontSize: "medium",
  size: "medium",
  marginRight: "27px",
};

const hideOnSmallScreen = {
  "@media (max-width: 750px)": {
    display: "none",
  },
};

const AppBarComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const nav = useNavigate();
  const location = useLocation();
  const currentUser = auth.currentUser;
  const showSignUp = !currentUser && !location.pathname.startsWith("/new-chef");
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let margin;
  if (!showSignUp) {
    margin = "auto";
  } else {
    margin = "14px";
  }

  const menuIconStyle = {
    display: "none", // Initially hide the menu icon
  };

  const mobileMenuIconStyle = {
    "@media (max-width: 750px)": {
      display: "block",
      marginLeft: `${margin}`,
    },
  };

  return (
    <>
      <AppBar position="static" sx={appBarStyle}>
        <ShutdownModal dismissible={true}/>
        <Toolbar>
          <Link to="/" style={{textDecoration: "none", color: "inherit"}}>
            <picture>
              <source media="(max-width: 850px)" srcSet={ChefsAndHomeMobileLogo}/>
              <img
                  style={{ padding: "5px", height: "40px" }}
                  src={ChefsAndHomeLogo}
                  alt="Chefs & Home"
              />
            </picture>
          </Link>

          <Button
            sx={{
              ...buttonStyle,
              marginLeft: "auto",
              ...hideOnSmallScreen,
            }}
            onClick={() => nav("/about")}
          >
            About Us
          </Button>
          {location.pathname !== "/chefs" && (
            <Button
              sx={{
                ...buttonStyle,
                marginLeft: "24px",
                ...hideOnSmallScreen,
              }}
              onClick={() => nav("/chefs")}
            >
              Meet our Chefs
            </Button>
          )}
          {location.pathname !== "/testimonials" && (
              <Button
                  sx={{
                    ...buttonStyle,
                    marginLeft: "24px",
                    ...hideOnSmallScreen,
                  }}
                  onClick={() => nav("/testimonials")}
              >
                Testimonials
              </Button>
          )}
          {location.pathname !== "/profile" && currentUser && (
            <Button
              sx={{
                ...buttonStyle,
                marginLeft: "24px",
                ...hideOnSmallScreen,
              }}
              onClick={() => nav("/profile")}
            >
              My Profile
            </Button>
          )}
          <Link to="https://www.instagram.com/chefsandhome?utm_source=chefsandhome.com&utm_medium=navbar&utm_campaign=brand_awareness&utm_content=navbar">
            <IconButton
                size="large"
            >
              <Instagram/>
            </IconButton>
          </Link>
          {currentUser ? (
            <IconButton
              size="large"
              sx={{ ...hideOnSmallScreen }}
              onClick={handleMenuOpen}
            >
              <Avatar
                style={{
                  border: "1px solid #F9A826",
                  boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.2)",
                  borderColor: "#F9A826",
                }}
                alt={auth.currentUser.displayName}
                src={auth.currentUser.photoURL}
              />
            </IconButton>
          ) : (
            <>
              <Button
                sx={{ ...buttonStyle, ...hideOnSmallScreen }}
                onClick={() => nav("/login")}
              >
                Log in
              </Button>
            </>
          )}
          {showSignUp && (
              <Button
                  sx={{
                    margin: "0 0",
                    backgroundColor: "#F9A826",
                    borderRadius: "40px",
                    marginLeft: "auto",
                    color: "white",
                    display: "block",

                    "&:hover": {
                      backgroundColor: "black",
                    },
                    "@media (max-width: 750px)": {
                      display: "block",
                    },
                  }}
                  variant={"contained"}
                  size={"small"}
                  onClick={() => nav("/login")}
              >
                Sign up
              </Button>
          )}

          <IconButton
            size="large"
            onClick={handleMenuOpen}
            sx={{ ...menuIconStyle, ...mobileMenuIconStyle }}
          >
            <MenuOutlinedIcon fontSize={"medium"} style={{ color: "black" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuComponent anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </>
  );
};

export default AppBarComponent;
