import React, { useState, useRef } from 'react';

const ShutdownModal = ({ dismissible }) => {
    const [isVisible, setIsVisible] = useState(true);
    const modalRef = useRef(null);

    const closeModal = () => {
        if (dismissible) {
            setIsVisible(false);
        } else {
            // Trigger wobble animation
            modalRef.current.classList.add('wobble');
            setTimeout(() => {
                modalRef.current.classList.remove('wobble');
            }, 1000); // Remove class after animation completes
        }
    };

    if (!isVisible) return null;

    return (
        <div style={overlayStyle}>
            <div ref={modalRef} style={modalStyle}>
                <button style={closeButtonStyle} onClick={closeModal}>
                    &times;
                </button>
                <h2 style={headingStyle}>Say Hello to Viva Chefs!</h2>
                <div style={logoContainerStyle}>
                    <img
                        src="/apple-touch-icon.png"
                        alt="Chefs and Home Logo"
                        style={logoStyle}
                    />
                    <span style={arrowStyle}>→</span>
                    <img
                        src="/viva-v.png"
                        alt="Viva Chefs Logo"
                        style={logoStyle}
                        height="75"
                    />
                </div>
                <p style={paragraphStyle}>
                    After cooking over <strong>15,000 meals</strong>, we've made some changes! Chefs and Home is now{' '}
                    <strong>Viva Chefs</strong>. Visit us at <a href="https://www.vivachefs.com?utm_source=chefs_and_home_modal&utm_medium=referral&utm_campaign=launch" style={{ color: '#D43D88' }}>our website</a> to learn more and <strong>download
                    our app</strong>!
                </p>
                <a
                    href="https://www.vivachefs.com?utm_source=chefs_and_home_modal&utm_medium=referral&utm_campaign=launch"
                    style={{ ...waitlistButtonStyle, textDecoration: 'none', display: 'inline-block' }}
                    onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = waitlistButtonHoverStyle.backgroundColor)
                    }
                    onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = waitlistButtonStyle.backgroundColor)
                    }
                >
                    Visit Viva Chefs
                </a>
            </div>
        </div>
    );
};

// Styles
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
};

const modalStyle = {
    position: 'relative',
    width: '90%',
    maxWidth: '600px',
    backgroundColor: '#fff',
    padding: '20px 30px',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: "'Open Sans', sans-serif",
    boxSizing: 'border-box',
};

const headingStyle = {
    fontSize: '26px',
    marginBottom: '20px',
    color: '#333',
    fontWeight: '700',
};

const logoContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '20px',
};

const logoStyle = {
    width: '75px',
    height: '75px',
    margin: '0 10px',
    objectFit: 'contain',
};

const arrowStyle = {
    fontSize: '36px',
    margin: '0 10px',
    color: '#333',
};

const paragraphStyle = {
    fontSize: '18px',
    lineHeight: '1.5',
    color: '#555',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '15px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    color: '#aaa',
    transition: 'color 0.3s',
};

const waitlistButtonStyle = {
    backgroundColor: '#D43D88',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '20px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
};

const waitlistButtonHoverStyle = {
    backgroundColor: '#CF2814',
};

// Add a style tag for the wobble animation
const styleTag = `
    @keyframes wobble {
        0% { transform: translateX(0%); }
        15% { transform: translateX(-25%) rotate(-5deg); }
        30% { transform: translateX(20%) rotate(3deg); }
        45% { transform: translateX(-15%) rotate(-3deg); }
        60% { transform: translateX(10%) rotate(2deg); }
        75% { transform: translateX(-5%) rotate(-1deg); }
        100% { transform: translateX(0%); }
    }

    .wobble {
        animation: wobble 0.7s ease-in-out;
    }
`;

export default ({dismissible}) => (
    <>
        <style>{styleTag}</style>
        <ShutdownModal dismissible={dismissible} />
    </>
);